import { InfiniteScroll, List } from 'antd-mobile'
import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Page from '../../../App/Page'
import LoadingComp from '../../../Components/LoadingComp'
import VideoPreview from '../../../Components/VideoPreview'
import { VideoModel } from '../../Video/Model'
import { getVideoListByChannel } from '../Service'

interface IState {
  loading: boolean
  pageIndex: number
  hasMore: boolean
  videoList: VideoModel[]
}

const ChannelDetailPage: FC = () => {
  const pageSize = 20

  const history = useHistory()

  const params = useParams<{ id: string }>()

  const [state, setState] = useState<IState>({
    loading: false,
    pageIndex: 1,
    videoList: [],
    hasMore: true,
  })

  async function reload() {
    setState({
      loading: true,
      pageIndex: 1,
      videoList: [],
      hasMore: true,
    })
    await loadMore()
  }

  async function loadMore() {
    setState({
      ...state,
      loading: true,
    })

    const result = await getVideoListByChannel(
      state.pageIndex,
      pageSize,
      params.id
    )

    setState({
      loading: false,
      pageIndex: state.pageIndex + 1,
      videoList: [...state.videoList, ...result],
      hasMore: result.length >= pageSize,
    })
  }

  function goBack() {
    if (history.length < 3) {
      history.replace('/')
    } else {
      history.goBack()
    }
  }

  useEffect(() => {
    reload()
    // eslint-disable-next-line
  }, [params.id])

  return (
    <Page title="频道详情" onBack={goBack}>
      <div className="video-page">
        <List className="video-list">
          {state.videoList.map((item) => (
            <VideoPreview video={item} key={item.id} />
          ))}
        </List>
        {state.loading ? (
          <LoadingComp />
        ) : (
          <InfiniteScroll loadMore={() => loadMore()} hasMore={state.hasMore} />
        )}
      </div>
    </Page>
  )
}

export default ChannelDetailPage
