import { Avatar } from 'antd-mobile'
import { FC } from 'react'
import { VideoItem } from '../../Pages/Video/Model'
import { timeago } from '../../Utils/Helper'
import './index.scss'

interface VideoInfoProps {
  video: VideoItem
  longTitle?: boolean
}

const VideoInfo: FC<VideoInfoProps> = ({ video, longTitle = false }) => {
  const titleClassName = longTitle ? 'video-title' : 'video-title short'

  const viewCountStr =
    video.viewCount > 10000
      ? `${(video.viewCount / 10000).toFixed(2)}万次观看`
      : `${video.viewCount}次观看`

  return (
    <div className="video-info">
      <Avatar className="avatar" src={video.channelAvatar} />

      <div className="title-info">
        <div className={titleClassName}>{video.title}</div>
        <div className="channel-title">{video.channelTitle}</div>
        <div className="video-statistic">
          {timeago(video.publishTime)}
          <div>{viewCountStr}</div>
        </div>
      </div>
    </div>
  )
}

export default VideoInfo
