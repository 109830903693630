import { TabBar } from 'antd-mobile'
import { AppOutline, UnorderedListOutline } from 'antd-mobile-icons'
import { useEffect, useState } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { CacheSwitch, CacheRoute } from 'react-router-cache-route'
import Channel from '../Pages/Channel'
import News from '../Pages/News'
import Video from '../Pages/Video'
import Yizhan from '../Pages/Yizhan'
import './index.scss'
import ChannelDetailPage from '../Pages/Channel/Detail'
import VideoDetailPage from '../Pages/Video/Detail'
import NewsDetailPage from '../Pages/News/Detail'

function Home() {
  const tabs = [
    {
      key: 'home',
      title: '首页',
      icon: <AppOutline />,
    },
    {
      key: 'channel',
      title: '频道',
      icon: <UnorderedListOutline />,
    },
  ]

  const [activeKey, setActiveKey] = useState('home')

  const [showTabbar, setShowTabbar] = useState(true)

  const history = useHistory()

  const menuKeys = ['/', '/channel', '/news', '/yizhan']

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (menuKeys.indexOf(location.pathname) > -1) {
        setShowTabbar(true)
      } else {
        setShowTabbar(false)
      }
    })

    return () => unlisten()
    // eslint-disable-next-line
  }, [history])

  useEffect(() => {
    var pathName = history.location.pathname
    if (pathName && menuKeys.indexOf(pathName) > -1) {
      setActiveKey(pathName.substring(1))
    }
    // eslint-disable-next-line
  }, [])

  function changePage(key: string) {
    setActiveKey(key)
    if (key === 'home') {
      history.push('/')
    } else if (key === 'channel') {
      history.push('/channel')
    } else if (key === 'news') {
      history.push('/news')
    } else if (key === 'yizhan') {
      history.push('/yizhan')
    }
  }

  return (
    <div className="app">
      <div className="app-body">
        <CacheSwitch>
          <CacheRoute
            when="always"
            saveScrollPosition
            path="/"
            component={Video}
            exact
          />
          <CacheRoute
            when="always"
            saveScrollPosition
            path="/channel"
            component={Channel}
            exact
          />
          <CacheRoute
            when="always"
            saveScrollPosition
            path="/news"
            component={News}
            exact
          />
          <CacheRoute
            when="always"
            saveScrollPosition
            path="/yizhan"
            component={Yizhan}
            exact
          />
        </CacheSwitch>

        <CacheRoute path="/channel/:id" component={ChannelDetailPage} exact />

        <Route path="/v/:id" component={VideoDetailPage} exact />

        <Route path="/news/:id" component={NewsDetailPage} exact />
      </div>

      {showTabbar ? (
        <TabBar
          safeArea
          className="app-tabbar"
          activeKey={activeKey}
          onChange={changePage}
        >
          {tabs.map((item) => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      ) : null}
    </div>
  )
}

export default Home
