import axios from 'axios'

export interface Pagin<T> {
  items: T[]
  totalCount: number
}

export interface HttpRes<T> {
  success: boolean
  result: T
  error: string
}

export class HttpError {
  constructor(code: number, message: string) {
    this.code = code
    this.message = message
  }

  code: number = 500
  message: string = ''
}

const instance = axios.create({
  baseURL: '/',
  timeout: 10000,
})

export const http = instance

export async function get<T>(url: string, params?: any) {
  let qs = ''
  if (params) {
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const element = params[key]
        qs += `&${key}=${element}`
      }
    }

    qs = qs.substring(1)
    qs = '?' + qs
  }

  const res = await instance.get<HttpRes<T>>(url + qs)

  if (res.status !== 200) {
    throw new HttpError(res.status, res.statusText)
  }

  return res.data
}

export async function post<T>(url: string, data?: any) {
  const res = await instance.post<HttpRes<T>>(url, data)

  if (res.status !== 200) {
    throw new HttpError(res.status, res.statusText)
  }

  return res.data
}
