import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Page from '../../../App/Page'
import LoadingComp from '../../../Components/LoadingComp'
import { get } from '../../../Utils/Http'
import { News } from '../Model'
import './index.scss'

interface IState {
  news: News
  loading: boolean
}

const NewsDetailPage: FC = () => {
  const params = useParams<{ id: string }>()

  const history = useHistory()

  const [state, setState] = useState({ news: {}, loading: false } as IState)

  useEffect(() => {
    loadNews()
    // eslint-disable-next-line
  }, [params.id])

  async function loadNews() {
    setState({ ...state, loading: true })

    const res = await get<News>(`/api/news/${params.id}`)

    if (res.success) {
      setState({
        loading: false,
        news: res.result,
      })
    }
  }

  function goBack() {
    if (history.length < 3) {
      history.replace('/')
    } else {
      history.goBack()
    }
  }

  if (state.loading) {
    return <LoadingComp />
  }

  return (
    <Page onBack={goBack} title="新闻详情">
      <div className="news-detail-page">
        <div className="title">{state.news.title}</div>
        <div className="time">{state.news.publishTime}发布</div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: state.news.content }}
        ></div>
      </div>
    </Page>
  )
}

export default NewsDetailPage
