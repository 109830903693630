import { ProgressBar, Image } from 'antd-mobile'
import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { VideoItem } from '../../Pages/Video/Model'
import { convertSeconds, convertTime, getProgress } from '../../Utils/Helper'
import VideoInfo from '../VideoInfo'
import './index.scss'

interface VideoPreviewProps {
  video: VideoItem
}

const VideoPreview: FC<VideoPreviewProps> = ({ video }) => {
  const history = useHistory()

  const width = document.body.clientWidth - 24

  const height = (width / 16) * 9

  const destDuration = convertTime(video.duration)

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const totalSeconds = convertSeconds(video.duration)
    const currentTime = getProgress(video.id)
    if (totalSeconds > 0) {
      setProgress((currentTime / totalSeconds) * 100)
    }
  }, [video])

  function gotoDetail() {
    history.push(`/v/${video.id}`)
  }

  return (
    <div className="video-item" onClick={gotoDetail}>
      <div className="thumbnail">
        <Image
          className="image"
          src={video.thumbnail}
          width={width}
          height={height}
          fit="cover"
        />
        <div className="duration">{destDuration}</div>

        {progress > 0 ? (
          <ProgressBar
            percent={progress}
            className="progress-bar"
            style={{
              '--track-color': '#999',
            }}
          />
        ) : null}
      </div>

      <VideoInfo video={video} />
    </div>
  )
}
export default VideoPreview
