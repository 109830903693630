import { InfiniteScroll } from 'antd-mobile'
import { FC, useEffect, useState } from 'react'
import LoadingComp from '../../Components/LoadingComp'
import VideoPreview from '../../Components/VideoPreview'
import { VideoModel } from './Model'
import { get, Pagin } from '../../Utils/Http'
import Page from '../../App/Page'
import './index.scss'

const pageSize = 20

interface IState {
  loading: boolean
  hasMore: boolean
  pageIndex: number
  videoList: VideoModel[]
}

const defaultState: IState = {
  loading: false,
  hasMore: true,
  pageIndex: 1,
  videoList: [],
}

const Video: FC = () => {
  const [state, setState] = useState(defaultState)

  useEffect(() => {
    reload()
    // eslint-disable-next-line
  }, [])

  async function reload() {
    setState({
      ...state,
      pageIndex: 1,
      videoList: [],
    })

    await loadMore()
  }

  async function loadMore() {
    setState({ ...state, loading: true })

    const res = await get<Pagin<VideoModel>>('/api/video/list', {
      pageIndex: state.pageIndex,
      pageSize: pageSize,
    })

    if (res.success) {
      setState({
        loading: false,
        hasMore: state.videoList.length < res.result.totalCount,
        pageIndex: state.pageIndex + 1,
        videoList: [...state.videoList, ...res.result.items],
      })
    }
  }

  return (
    <Page backArrow={false}>
      <div className="video-page">
        <div className="video-list">
          {state.videoList.map((item) => (
            <VideoPreview video={item} key={item.id} />
          ))}
        </div>
        {state.loading ? (
          <LoadingComp />
        ) : (
          <InfiniteScroll loadMore={loadMore} hasMore={state.hasMore} />
        )}
      </div>
    </Page>
  )
}

export default Video
