import { format } from 'timeago.js'

export function timeago(date: string) {
  return format(date, 'zh_CN')
}

export function convertTime(duration: string) {
  var input = duration
  var reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/
  var hours = 0,
    minutes = 0,
    seconds = 0

  const doubleStr = (input: number) =>
    input > 9 ? input.toString() : `0${input}`

  if (reptms.test(input)) {
    var matches = reptms.exec(input)
    if (matches) {
      if (matches[1]) hours = Number(matches[1])
      if (matches[2]) minutes = Number(matches[2])
      if (matches[3]) seconds = Number(matches[3])

      return `${doubleStr(hours)}:${doubleStr(minutes)}:${doubleStr(seconds)}`
    }
  }
  return ''
}

export function convertSeconds(duration: string) {
  var input = duration
  var reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/
  var hours = 0,
    minutes = 0,
    seconds = 0

  if (reptms.test(input)) {
    var matches = reptms.exec(input)
    if (matches) {
      if (matches[1]) hours = Number(matches[1])
      if (matches[2]) minutes = Number(matches[2])
      if (matches[3]) seconds = Number(matches[3])

      return hours * 60 * 60 + minutes * 60 + seconds
    }
  }
  return 0
}

export function saveProgress(id: string, progress: number) {
  localStorage.setItem(`video_progress_${id}`, progress.toString())
}

export function getProgress(id: string) {
  const progress = localStorage.getItem(`video_progress_${id}`)
  if (progress) {
    return Number.parseInt(progress)
  }
  return 0
}

export function saveDuration(id: string, duration: number) {
  localStorage.setItem(`video_duration_${id}`, duration.toString())
}

export function getDuration(id: string) {
  const duration = localStorage.getItem(`video_duration_${id}`)
  if (duration) {
    return Number.parseInt(duration)
  }
  return 0
}
