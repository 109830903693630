import { DotLoading } from 'antd-mobile'

function LoadingComp() {
  const height = 400
  return (
    <div
      style={{
        height: height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '12px',
        color: '#999',
      }}
    >
      <DotLoading color="primary" />
      <div className="text">加载中...</div>
    </div>
  )
}

export default LoadingComp
