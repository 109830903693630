import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Page from '../../../App/Page'
import { Comment, VideoModel } from '../Model'
import { getCommentList, getVideo } from '../Service'
import Player, { PlayerType } from '../../../Components/Player/Player'
import { Selector, Space } from 'antd-mobile'
import VideoInfo from '../../../Components/VideoInfo'
import LoadingComp from '../../../Components/LoadingComp'
import CommentItem from '../../../Components/CommentItem'
import './index.scss'
import 'plyr/dist/plyr.css'

const VideoDetailPage: FC = () => {
  const history = useHistory()

  const params = useParams<{ id: string }>()

  const [video, setVideo] = useState<VideoModel>()

  const [commentList, setCommentList] = useState<Comment[]>()

  const [playerType, setPlayerType] = useState(PlayerType.Video)

  //#region 加载视频信息
  useEffect(() => {
    getVideo(params.id).then((res) => {
      setVideo(res)
    })

    getCommentList(params.id).then((res) => {
      setCommentList(res.result)
    })
  }, [params.id])
  //#endregion

  //#region 加载、存储播放类型
  useEffect(() => {
    var vtype = localStorage.getItem('video_type')
    var vtypeNum = Number.parseInt(vtype ?? '0')
    setPlayerType(vtypeNum)
  }, [])

  useEffect(() => {
    return () => {
      console.log(playerType)
      localStorage.setItem('video_type', playerType.toString())
    }
    // eslint-disable-next-line
  }, [playerType])
  //#endregion

  console.log(playerType)

  function goBack() {
    if (history.length < 3) {
      history.replace('/')
    } else {
      history.goBack()
    }
  }

  function changeType(types: PlayerType[]) {
    setPlayerType(types[0])
  }

  if (!video)
    return (
      <Page title="视频详情" onBack={goBack}>
        <LoadingComp />
      </Page>
    )

  const types = [
    { label: '视频', value: PlayerType.Video },
    { label: '音频', value: PlayerType.Audio },
  ]

  return (
    <Page title="视频详情" onBack={goBack}>
      <div className="sticky">
        <Player video={video!} type={playerType} />
      </div>

      <div className="detail-info">
        <Space direction="vertical">
          <Selector
            defaultValue={[playerType]}
            onChange={changeType}
            options={types}
          />

          <VideoInfo video={video!} longTitle />
        </Space>
      </div>

      <div className="comment-info">
        <div className="title">评论区域</div>

        <div className="comment-list">
          {commentList?.map((item) => (
            <CommentItem comment={item} key={item.id} />
          ))}
        </div>
      </div>
    </Page>
  )
}

export default VideoDetailPage
