import { Modal } from 'antd-mobile'
import { get } from '../../Utils/Http'
import { Comment, VideoModel } from './Model'

export async function getVideo(id: string) {
  const res = await get<VideoModel>(`/api/video/${id}`)
  if (res.success) {
    return res.result
  } else {
    Modal.alert({
      title: '加载视频出错',
      content: res.error,
    })
    throw res.error
  }
}

export async function getCommentList(videoId: string) {
  const res = await get<Comment[]>(`/api/comment/list?videoId=${videoId}`)
  return res
}
