import { http } from '../../Utils/Http'

function Yizhan() {
  const url = http.defaults.baseURL?.replace('www', 'yizhan')

  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        overflow: 'hidden',
      }}
    >
      <iframe
        title="真相驿站"
        style={{ width: '100%', height: '100%', border: 'none' }}
        src={url}
      ></iframe>
    </div>
  )
}

export default Yizhan
