import { FC, useEffect, useState } from 'react'
import Page from '../../App/Page'
import { get, Pagin } from '../../Utils/Http'
import { News } from './Model'
import './index.scss'
import { useHistory } from 'react-router-dom'
import { format } from 'timeago.js'
import LoadingComp from '../../Components/LoadingComp'

const pageSize = 20

interface IState {
  newsList: News[]
  pageIndex: number
  loading: boolean
  hasMore: boolean
}

const defaultState: IState = {
  newsList: [],
  pageIndex: 1,
  loading: false,
  hasMore: true,
}

const NewsPage: FC = () => {
  const history = useHistory()

  const [state, setState] = useState(defaultState)

  useEffect(() => {
    reload()
    // eslint-disable-next-line
  }, [])

  async function reload() {
    setState({ ...state, pageIndex: 1, newsList: [] })
    await loadMore()
  }

  async function loadMore() {
    setState({ ...state, loading: true })
    const res = await get<Pagin<News>>(
      `/api/news/list?pageIndex=${state.pageIndex}&pageSize=${pageSize}`
    )

    if (res.success) {
      setState({
        loading: false,
        pageIndex: state.pageIndex + 1,
        hasMore: state.newsList.length < res.result.totalCount,
        newsList: [...state.newsList, ...res.result.items],
      })
    }
  }

  function gotoDetail(id: string) {
    history.push(`/news/${id}`)
  }

  return (
    <Page>
      {state.loading ? (
        <LoadingComp />
      ) : (
        state.newsList.map((item) => (
          <div className="news-item" onClick={() => gotoDetail(item.id)}>
            <div className="title">{item.title}</div>
            <div className="time">{format(item.publishTime, 'zh_CN')}</div>
          </div>
        ))
      )}
    </Page>
  )
}

export default NewsPage
