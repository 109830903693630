import { Avatar } from 'antd-mobile'
import { FC, useEffect, useState } from 'react'
import './index.scss'
import { useHistory } from 'react-router-dom'
import { Channel } from './Model'
import { get } from '../../Utils/Http'
import { timeago } from '../../Utils/Helper'
import LoadingComp from '../../Components/LoadingComp'

const pageSize = 20

interface IState {
  channelList: Channel[]
  loading: boolean
  pageIndex: number
}

const defaultState: IState = {
  channelList: [],
  loading: false,
  pageIndex: 1,
}

const ChannePage: FC = () => {
  const history = useHistory()

  const [state, setState] = useState(defaultState)

  async function load() {
    setState({
      ...state,
      loading: true,
    })

    const res = await get<Channel[]>(
      `/api/channel/list?pageIndex=${state.pageIndex}&pageSize=${pageSize}`
    )

    if (res.success) {
      setState({
        loading: false,
        channelList: [...state.channelList, ...res.result],
        pageIndex: state.pageIndex + 1,
      })
    }
  }

  useEffect(() => {
    load()
    // eslint-disable-next-line
  }, [])

  function gotoDetail(id: string) {
    history.push(`/channel/${id}`)
  }

  return (
    <div className="channel-page">
      {state.loading ? (
        <LoadingComp />
      ) : (
        state.channelList.map((item) => (
          <div
            className="channel-item"
            key={item.id}
            onClick={() => gotoDetail(item.id)}
          >
            <Avatar src={item.avatar} className="avatar" />
            <div className="info">
              <div className="title">{item.title}</div>
              <div className="update-time">{timeago(item.updateTime)}</div>
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default ChannePage
