import { Modal } from 'antd-mobile'
import { get } from '../../Utils/Http'
import { VideoModel } from '../Video/Model'

export async function getVideoListByChannel(
  pageIndex: number,
  pageSize: number,
  channelId: string
) {
  const res = await get<VideoModel[]>(
    `/api/video/list/${channelId}?pageIndex=${pageIndex}&pageSize=${pageSize}`
  )

  if (res.success) {
    return res.result
  } else {
    Modal.show({
      title: '加载视频失败',
      content: res.error,
    })

    return new Array<VideoModel>()
  }
}
