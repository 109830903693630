import { Avatar } from 'antd-mobile'
import { FC } from 'react'
import { Comment } from '../../Pages/Video/Model'
import { timeago } from '../../Utils/Helper'
import './index.scss'

interface CommentItemProps {
  comment: Comment
}

const CommentItem: FC<CommentItemProps> = ({ comment }) => {
  return (
    <div className="comment-item">
      <Avatar className="profile-image" src={comment.authorProfileImageUrl} />

      <div className="comment-body">
        <div className="user-info">{comment.authorName}</div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: comment.text }}
        ></div>

        <div className="bottom-content">
          <div className="like-count">{comment.likeCount}个点赞</div>
          <div className="time">{timeago(comment.publishTime)}</div>
        </div>
      </div>
    </div>
  )
}

export default CommentItem
