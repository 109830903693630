import { FC, useEffect, useLayoutEffect, useRef } from 'react'
import { VideoModel } from '../../Pages/Video/Model'
import { getProgress, saveDuration, saveProgress } from '../../Utils/Helper'
import './Player.scss'

export enum PlayerType {
  Video,
  Audio,
}

interface PlayerProps {
  video: VideoModel
  type: PlayerType
}

const Player: FC<PlayerProps> = ({ video, type }) => {
  const ref = useRef<any>()

  const height = (document.body.clientWidth / 16) * 9

  useEffect(() => {
    if (!video) return

    if (ref.current) {
      const player = ref.current as HTMLVideoElement
      const seconds = getProgress(video.id)
      player.currentTime = seconds
    }
  }, [video, type])

  useLayoutEffect(() => {
    if (!video) return

    const player = ref.current as HTMLVideoElement
    return () => {
      saveProgress(video.id, player.currentTime)
      saveDuration(video.id, player.duration)
    }
  }, [video, type])

  return type === PlayerType.Video ? (
    <video
      autoPlay
      controls
      ref={ref}
      src={video.url}
      width="100%"
      height={height}
      className="video"
    />
  ) : (
    <audio autoPlay controls ref={ref} src={video.audioUrl} className="audio" />
  )
}

export default Player
