import { NavBar } from 'antd-mobile'
import React, { FC, useEffect, useRef } from 'react'
import './Page.scss'

interface PageProps {
  title?: string
  backArrow?: boolean
  onBack?: () => void
  bottom?: React.ReactNode
  left?: React.ReactNode
  right?: React.ReactNode
}

const Page: FC<PageProps> = (props) => {
  const latestScrollTop = useRef(0)

  useEffect(() => {
    window.onscroll = (e) => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop

      const navBar = document.getElementById('nav-bar')
      if (!navBar) return

      const top = Number.parseInt(navBar.style.top || '0')

      if (latestScrollTop.current < scrollTop) {
        if (Math.abs(top) < navBar.clientHeight) {
          navBar.style.top = top - (scrollTop - latestScrollTop.current) + 'px'
        } else {
          navBar.style.top = -1 * navBar.clientHeight + 'px'
        }
      } else {
        if (top < 0) {
          const temp = top + latestScrollTop.current - scrollTop
          navBar.style.top = (temp > 0 ? 0 : temp) + 'px'
        }
      }
      latestScrollTop.current = scrollTop
    }
  }, [])

  return (
    <div className="main">
      <div className="nav-bar" id="nav-bar">
        {props.title ? <NavBar {...props}>{props.title}</NavBar> : null}
        {props.bottom}
      </div>
      <div className="content">{props.children}</div>
    </div>
  )
}

export default Page
